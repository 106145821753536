import "./App.css";
import Homepage from "./pages/homepage";
import { createTheme, ThemeProvider } from "@mui/material";
import { Palette } from "./palette";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import NewRequests from "./pages/newRequests";

const theme = createTheme({
	palette: Palette("light"),
});

function App() {
	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Homepage />} />
					<Route path="/request/new" element={<NewRequests />} />
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;
