import React from "react";
import {
	Box,
	Button,
	Container,
	AppBar,
	Toolbar,
	Typography,
	Paper,
	Icon,
} from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";

const Homepage: React.FC = () => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				minHeight: "100vh",
				minWidth: "100%",
				bgcolor: (theme) =>
					theme.palette.mode === "light"
						? "background.default"
						: "background.dark",
				color: (theme) =>
					theme.palette.mode === "light"
						? "text.primary"
						: "text.secondary",
				transition: (theme) =>
					theme.transitions.create("background-color"),
				position: "absolute",
			}}
		>
			<AppBar
				position="static"
				sx={{
					bgcolor: "white",
					boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 0px 0px",
				}}
			>
				<Toolbar>
					<Typography variant="h6" style={{ flexGrow: 1 }}>
						<img
							src="/images/second_brain_logo_with_long_text_background_green.svg"
							alt="Logo"
							style={{ height: "30px" }}
						/>
					</Typography>
					<Button
						color="inherit"
						variant="outlined"
						sx={{
							marginRight: "10px",
						}}
						onClick={() => {
							window.location.href = "/request/new";
						}}
					>
						Submit a Request
					</Button>
					<Button
						color="inherit"
						startIcon={<LoginIcon />}
						onClick={() => {
							window.location.href = "https://support.sbrain.io";
						}}
					>
						Sign In
					</Button>
				</Toolbar>
			</AppBar>

			<Paper
				style={{
					width: "100%",
					height: "300px",
					background:
						"linear-gradient(to right, #38ef7d78, #11998e8c)",
					display: "flex",
					boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 0px 0px",
				}}
			>
				<Box style={{ padding: "20px", margin: "auto" }}>
					<Typography
						variant="h4"
						sx={{
							textAlign: "center",
							color: "rgba(30,70,32)",
						}}
					>
						Welcome to the Support Portal
					</Typography>
				</Box>
			</Paper>
			<Box
				sx={{
					margin: "auto",
					marginTop: "20px",
					width: "50%",
				}}
			>
				<Box
					sx={{
						marginTop: "100px",
					}}
				>
					<Box
						sx={{
							textAlign: "center",
							marginBottom: "20px",
							width: "100%",
							flexDirection: "row",
							display: "flex",
						}}
					>
						<Paper
							style={{
								borderRadius: "20px",
								padding: "20px",
								height: "300px",
								border: "1px solid #ccc",
								textAlign: "center",
								width: "40%",
								margin: "auto",
								display: "flex",
								flexDirection: "column",
								position: "relative",
								boxShadow:
									"rgba(0, 0, 0, 0.1) 0px 0px 20px 12px",
							}}
						>
							<Typography
								variant="h5"
								sx={{
									color: "#183455",
									fontWeight: "bold",
									fontFamily: "Proxima Nova Regular",
								}}
							>
								Submit a Request
							</Typography>
							<img
								src="/images/job-application.png"
								style={{
									marginTop: "20px",
									marginRight: "auto",
									marginLeft: "auto",
									height: "60px",
									width: "60px",
								}}
								alt="Submit ticket"
							/>
							<Typography
								variant="body1"
								style={{
									marginTop: "10px",
									color: "#666",
									fontSize: "16px",
									fontStyle: "italic",
									fontFamily: "Proxima Nova Regular",
								}}
							>
								If you need help or have a question, please
								provide the details below, and our team will get
								back to you as soon as possible.
							</Typography>

							<Button
								variant="contained"
								color="primary"
								style={{
									position: "absolute",
									bottom: 40,
									right: "50%",
									transform: "translate(50%, 0)",
									fontFamily: "Proxima Nova Regular",
									fontWeight: "bold",
									padding: "10px 20px",
									color: "white",
									fontSize: "16px",
									width: "150px",
								}}
								onClick={() => {
									window.location.href = "/request/new";
								}}
							>
								Submit
							</Button>
						</Paper>
						<Paper
							style={{
								borderRadius: "20px",
								padding: "20px",
								height: "300px",
								border: "1px solid #ccc",
								textAlign: "center",
								width: "40%",
								margin: "auto",
								position: "relative",
								boxShadow:
									"rgba(0, 0, 0, 0.1) 0px 0px 20px 12px",
							}}
						>
							<Typography
								variant="h5"
								sx={{
									color: "#183455",
									fontWeight: "bold",
									fontFamily: "Proxima Nova Regular",
								}}
							>
								List Tickets
							</Typography>
							<img
								src="/images/insect.png"
								style={{
									marginTop: "20px",
									marginRight: "auto",
									marginLeft: "auto",
									height: "60px",
									width: "60px",
								}}
								alt="Submit ticket"
							/>
							<Typography
								variant="body1"
								style={{
									marginTop: "10px",
									color: "#666",
									fontSize: "16px",
									fontStyle: "italic",
									fontFamily: "Proxima Nova Regular",
								}}
							>
								View your submitted tickets below and track
								their status.
							</Typography>
							<Button
								variant="contained"
								color="primary"
								style={{
									position: "absolute",
									bottom: 40,
									right: "50%",
									transform: "translate(50%, 0)",
									fontFamily: "Proxima Nova Regular",
									fontWeight: "bold",
									padding: "10px 20px",
									color: "white",
									fontSize: "16px",
									width: "150px",
								}}
								onClick={() => {
									window.location.href =
										"https://support.sbrain.io";
								}}
							>
								View Tickets
							</Button>
						</Paper>
					</Box>
				</Box>
			</Box>
			<footer
				style={{
					background: "#39c88a",
					color: "white",
					padding: "20px",
					textAlign: "center",
					position: "relative",
					marginBottom: "0%",
				}}
			>
				<Typography
					variant="body1"
					style={{
						fontFamily: "Proxima Nova Regular",
						fontWeight: "bold",
					}}
				>
					© {new Date().getFullYear()} Second Brain. All rights
					reserved.
				</Typography>
			</footer>
		</Box>
	);
};

export default Homepage;
