import {
	AppBar,
	Toolbar,
	Typography,
	Button,
	Box,
	Paper,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Divider,
	Alert,
	Snackbar,
	AlertColor,
} from "@mui/material";
import React, { useState } from "react";
import LoginIcon from "@mui/icons-material/Login";
import { Send } from "@mui/icons-material";
import MDEditor from "@uiw/react-md-editor";
import axios from "axios";

const NewRequests: React.FC = () => {
	const [reportType, setReportType] = useState<string | undefined>(undefined);
	const [name, setName] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [subject, setSubject] = React.useState("");

	const [displayAlert, setDisplayAlert] = React.useState(false);
	const [alertMessage, setAlertMessage] = React.useState("");
	const [alertType, setAlertType] = React.useState<AlertColor>("info");

	const defaultText =
		"Please describe the feedback you have for the feature you are using and how we can improve it." +
		"\n\r**Note**: This is a markdown editor. You can use markdown syntax to format your text." +
		"\n\rFor images, please provide an existing link.";
	const [value, setValue] = React.useState(defaultText);

	const handleReportTypeChange = (event: any) => {
		setReportType(event.target.value);
	};

	const send_feedback = () => {
		console.log("Feedback submitted");
		axios
			.post("https://api.help.sbrain.io/feedback", {
				name: name,
				email: email,
				subject: subject,
				description: value,
			})
			.then((response) => {
				console.log(response);
				setEmail("");
				setName("");
				setSubject("");
				setValue(defaultText);
				setAlertMessage(
					"Feedback submitted successfully. Thank you 🎉!"
				);
				setAlertType("success");
				setDisplayAlert(true);
			})
			.catch((error) => {
				console.log(error);
				setAlertMessage(
					"Failed to submit feedback, sorry 😞. Our engineer has been notified, let's get in touch soon."
				);
				setAlertType("error");
				setDisplayAlert(true);
			});
	};

	const handleClose = (event?: any, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setDisplayAlert(false);
		setAlertMessage("");
		setAlertType("info");
	};

	const send_issue = () => {
		console.log("Issue submitted");
		axios
			.post("https://api.help.sbrain.io/issue", {
				name: name,
				email: email,
				subject: subject,
				description: value,
			})
			.then((response) => {
				console.log(response);
				setEmail("");
				setName("");
				setSubject("");
				setValue(defaultText);
				setAlertMessage(
					"Issue submitted successfully. Thanks for reporting. An agent will get back to you soon."
				);
				setAlertType("success");
				setDisplayAlert(true);
			})
			.catch((error) => {
				console.log(error);
				setAlertMessage(
					"Failed to submit issue, sorry 😞. Our engineer has been notified, let's get in touch soon."
				);
				setAlertType("error");
				setDisplayAlert(true);
			});
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				minHeight: "100vh",
				minWidth: "100%",
				bgcolor: (theme) =>
					theme.palette.mode === "light"
						? "background.default"
						: "background.dark",
				color: (theme) =>
					theme.palette.mode === "light"
						? "text.primary"
						: "text.secondary",
				transition: (theme) =>
					theme.transitions.create("background-color"),
				position: "absolute",
			}}
		>
			<AppBar
				position="static"
				sx={{
					bgcolor: "white",
					boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 0px 0px",
				}}
			>
				<Toolbar>
					<Typography variant="h6" style={{ flexGrow: 1 }}>
						<img
							src="/images/second_brain_logo_with_long_text_background_green.svg"
							alt="Logo"
							style={{ height: "30px", cursor: "pointer" }}
							onClick={() => {
								window.location.href = "/";
							}}
						/>
					</Typography>
					<Button
						color="inherit"
						startIcon={<LoginIcon />}
						onClick={() => {
							window.location.href = "https://support.sbrain.io";
						}}
					>
						Sign In
					</Button>
				</Toolbar>
			</AppBar>
			<Paper
				style={{
					width: "100%",
					height: "300px",
					background:
						"linear-gradient(to right, #38ef7d78, #11998e8c)",
					display: "flex",
					boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 0px 0px",
				}}
			>
				<Box style={{ padding: "20px", margin: "auto" }}>
					<Typography
						variant="h4"
						sx={{
							textAlign: "center",
							color: "rgba(30,70,32)",
						}}
					>
						Create a new request to the Second Brain team
					</Typography>
				</Box>
			</Paper>
			<Box
				sx={{
					margin: "auto",
					marginTop: "20px",
					padding: "20px",
					width: "50%",
					border: "1px solid #39c88a",
					borderRadius: "10px",
					boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
				}}
			>
				<FormControl fullWidth>
					<InputLabel id="request-type-select-label">
						Request type
					</InputLabel>
					<Select
						labelId="request-type-select-label"
						id="request-type-select"
						value={reportType}
						label="Request type"
						onChange={handleReportTypeChange}
					>
						<MenuItem value={""}>--Select-</MenuItem>
						<MenuItem value={"feedback"}>Feature feedback</MenuItem>
						<MenuItem value={"issue"}>Bug report</MenuItem>
					</Select>
				</FormControl>

				{reportType === "feedback" && (
					<Box
						sx={{
							marginTop: "20px",
						}}
					>
						<FormControl fullWidth>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: "20px",
								}}
							>
								<TextField
									id="name"
									required
									value={name}
									onChange={(e) => setName(e.target.value)}
									label="Name"
									variant="outlined"
									helperText="Please enter your name"
								/>
								<TextField
									id="email"
									required
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									label="Email"
									variant="outlined"
									helperText="Please enter your email"
								/>
							</Box>
							<Divider
								sx={{
									margin: "10px 0",
									width: "50%",
									marginX: "auto",
								}}
							/>
							<Typography
								variant="body1"
								sx={{
									textAlign: "center",
									fontFamily: "Proxima Nova Regular",
								}}
							>
								Please provide feedback on the feature you are
								using and how we can improve it
							</Typography>
							<TextField
								id="subject"
								required
								label="Feature subject"
								variant="outlined"
								value={subject}
								onChange={(e) => setSubject(e.target.value)}
								helperText="Please summarize the feature you are providing feedback on"
								sx={{
									marginTop: "10px",
								}}
							/>
							<div
								data-color-mode="light"
								style={{
									marginTop: "20px",
								}}
							>
								<MDEditor
									value={value}
									fullscreen={false}
									overflow={false}
									onChange={(val) => {
										setValue(val!);
									}}
								/>
							</div>
							<Button
								variant="contained"
								color="primary"
								sx={{
									margin: "20px auto",
									padding: "10px 20px",
									color: "white",
									fontWeight: "bold",
									fontFamily: "Proxima Nova Regular",
								}}
								endIcon={<Send />}
								onClick={() => {
									send_feedback();
								}}
							>
								Submit Feedback
							</Button>
						</FormControl>
					</Box>
				)}

				{reportType === "issue" && (
					<Box
						sx={{
							marginTop: "20px",
						}}
					>
						<FormControl fullWidth>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: "20px",
								}}
							>
								<TextField
									id="name"
									required
									value={name}
									onChange={(e) => setName(e.target.value)}
									label="Name"
									variant="outlined"
									helperText="Please enter your name"
								/>
								<TextField
									id="subject"
									required
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									label="Email"
									variant="outlined"
									helperText="Please enter your email"
								/>
							</Box>
							<Divider
								sx={{
									margin: "10px 0",
									width: "50%",
									marginX: "auto",
								}}
							/>
							<Typography
								variant="body1"
								sx={{
									textAlign: "center",
									fontFamily: "Proxima Nova Regular",
								}}
							>
								Please description the issue you are
								experiencing as detailed as possible and how we
								can reproduce.
							</Typography>
							<Typography
								variant="body1"
								sx={{
									textAlign: "center",
									fontFamily: "Proxima Nova Regular",
								}}
							>
								If you have a screenshot, please provide a link.
							</Typography>
							<Typography
								variant="body1"
								sx={{
									textAlign: "center",
									fontFamily: "Proxima Nova Regular",
								}}
							>
								Please provide the browser, device you are using
								and the date time of the issue.
							</Typography>
							<TextField
								id="subject"
								label="Issue subject"
								required
								value={subject}
								onChange={(e) => setSubject(e.target.value)}
								variant="outlined"
								helperText="Please summarize the feature you are providing feedback on"
								sx={{
									marginTop: "10px",
								}}
							/>
							<div
								data-color-mode="light"
								style={{
									marginTop: "20px",
								}}
							>
								<MDEditor
									value={value}
									fullscreen={false}
									overflow={false}
									style={{
										minHeight: "300px",
									}}
									onChange={(val) => {
										setValue(val!);
									}}
								/>
							</div>
							<Button
								variant="contained"
								color="primary"
								sx={{
									margin: "20px auto",
									padding: "10px 20px",
									color: "white",
									fontWeight: "bold",
									fontFamily: "Proxima Nova Regular",
								}}
								endIcon={<Send />}
								onClick={() => {
									send_issue();
								}}
							>
								Submit Issue
							</Button>
						</FormControl>
					</Box>
				)}
			</Box>
			<footer
				style={{
					background: "#39c88a",
					color: "white",
					padding: "20px",
					textAlign: "center",
					position: "relative",
					marginTop: "20px",
					bottom: 0,
				}}
			>
				<Typography
					variant="body1"
					style={{
						fontFamily: "Proxima Nova Regular",
						fontWeight: "bold",
					}}
				>
					© {new Date().getFullYear()} Second Brain. All rights
					reserved.
				</Typography>
			</footer>
			<Snackbar
				open={displayAlert}
				autoHideDuration={6000}
				onClose={handleClose}
			>
				<Alert onClose={handleClose} severity={alertType}>
					{alertMessage}
				</Alert>
			</Snackbar>
		</Box>
	);
};

export default NewRequests;
